<template>
  <thead
    role="rowgroup"
  >
    <tr>
      <th
        v-if="selectable"
        class="check-cell"
        scope="col"
        style="width: 3%;"
      >
        <div class="form-check">
          <input
            v-model="columnSelected"
            class="form-check-input-table"
            style="margin-left: -1.35em;"
            type="checkbox"
            @click.stop="selectAllRows"
          >
        </div>
      </th>
      <th
        v-for="field of fields"
        :key="field.key"
        role="columnheader"
        scope="col"
        :style="{ width: field.width }"
      >
        <div
          class="cell-header"
          :style="{
            'justify-content': field.centered ? 'center' : field.right ? 'flex-end' : '',
            'text-align': field.centered ? 'center' : '',
            'pointer-events': field.sortable ? '' : 'none'
          }"
          @click="sortField(field.apiName)"
        >
          <i
            v-if="field.key === 'link'"
            class="bi bi-link"
            :style="{ 'font-size': '1.2rem' }"
          />
          <span v-else-if="field.key === 'Icon'" />
          <span v-else>
            {{ field.key }}
          </span>
          <div
            v-if="field.sortable"
            class="sort-field"
          >
            <b-icon-caret-up-fill
              :class="{
                active: sortedUp && sortedField === field.apiName,
                disabled: sortedDown && sortedField === field.apiName
              }"
            />
            <b-icon-caret-down-fill
              :class="{
                active: sortedDown && sortedField === field.apiName,
                disabled: sortedUp && sortedField === field.apiName
              }"
            />
          </div>
        </div>
      </th>
    </tr>
  </thead>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'TableHead',

  props: {
    name: {
      type: String,
      default: ''
    },
    fields: {
      type: Array,
      default: () => { return []; }
    },
    selectable: {
      type: Boolean,
      default: false
    },
    defaultSorting: {
      type: Object,
      default: () => {
        return {
          sortedUp: null,
          sortedDown: null,
          sortedField: null
        };
      }
    },
    sortFunction: {
      type: Function,
      default: () => { return {}; }
    },
    allSelected: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      columnSelected: false,
      sortedUp: false,
      sortedDown: false,
      sortedField: null
    };
  },

  watch: {
    sortedField: function(newValue) {
      this.$emit('sorted', {
        direction: this.sortedDown ? '-' : '',
        field: newValue
      });
    },
    allSelected(newValue) {
      this.columnSelected = newValue;
    }
  },

  created() {
    if (this.defaultSorting.sortedField) {
      this.sortedUp = this.defaultSorting.sortedUp;
      this.sortedDown = this.defaultSorting.sortedDown;
      this.sortedField = this.defaultSorting.sortedField;
    }
  },

  methods: {
    ...mapMutations('table', [
      'SET_TABLE_LOADER',
    ]),

    selectAllRows() {
      this.columnSelected = !this.columnSelected;
      this.$emit('select-all', this.columnSelected);
    },

    sortField(field) {

      if (field !== this.sortedField) {
        this.sortedDown = false;
        this.sortedUp = false;
      }

      if (!this.sortedUp && !this.sortedDown) {

        this.SET_TABLE_LOADER({
          name: this.name,
          loading: true
        });
        this.sortFunction({
          direction: '',
          field: field,
          page: this.currentPage
        })
          .then(() => {
            this.sortedDown = false;
            this.sortedUp = true;
            this.sortedField = field;
            this.SET_TABLE_LOADER({
              name: this.name,
              loading: false
            });
          })
          .catch((err) => {
            console.error(err);
            this.SET_TABLE_LOADER({
              name: this.name,
              loading: false
            });
          });

      } else if (this.sortedUp && !this.sortedDown) {

        this.SET_TABLE_LOADER({
          name: this.name,
          loading: true
        });
        this.sortFunction({
          direction: '-',
          field: field,
          page: this.currentPage
        })
          .then(() => {
            this.sortedDown = true;
            this.sortedUp = false;
            this.sortedField = field;
            this.SET_TABLE_LOADER({
              name: this.name,
              loading: false
            });
          })
          .catch((err) => {
            console.error(err);
            this.SET_TABLE_LOADER({
              name: this.name,
              loading: false
            });
          });

      } else if (!this.sortedUp && this.sortedDown) {
        
        this.SET_TABLE_LOADER({
          name: this.name,
          loading: true
        });
        this.sortFunction({
          direction: '',
          field: field,
          page: this.currentPage
        })
          .then(() => {
            this.sortedDown = false;
            this.sortedUp = true;
            this.sortedField = field;
            this.SET_TABLE_LOADER({
              name: this.name,
              loading: false
            });
          })
          .catch((err) => {
            console.error(err);
            this.SET_TABLE_LOADER({
              name: this.name,
              loading: false
            });
          });

      }

    }
  }
};
</script>
