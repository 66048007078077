<template>
  <table
    role="table"
    :aria-labelledby="name"
  >
    <Head
      :name="name"
      :fields="fields"
      :selectable="selectable"
      :default-sorting="defaultSorting"
      :sort-function="sortFunction"
      :all-selected="allSelected"
      @select-all="selectColumn"
      v-on="$listeners"
    />
    <tbody role="rowgroup">
      <tr
        v-if="rows.length === 0"
      >
        <div style="margin: 0.5em; font-style: italic;">
          {{ $t('words.emptyTable') }}
        </div>
      </tr>
      <Row
        v-for="row in rows"
        :key="`${row.id}-${rowKey}`"
        ref="tableRowComponent"
        :route-to="routeTo"
        :row="row"
        :selectable="selectable"
        :selected="isColumnSelected"
        :allow-deletion="allowDeletion"
        :allow-duplication="allowDuplication"
        :allow-modification="allowModification"
        v-on="$listeners"
      />
    </tbody>
  </table>
</template>

<script>
import { mapState } from 'vuex';

import Head from '@/components/SearchablePaginatedTable/Head.vue';
import Row from '@/components/SearchablePaginatedTable/Row.vue';

export default {
  name: 'Table',

  components: {
    Head,
    Row
  },

  props: {
    name: {
      type: String,
      required: true,
      default: ''
    },
    fields: {
      type: Array,
      default: () => { return []; }
    },
    rows: {
      type: Array,
      default: () => { return []; }
    },
    routeTo: {
      type: String,
      default: null
    },
    defaultSorting: {
      type: Object,
      default: () => { return {
        sortedUp: null,
        sortedDown: null,
        sortedField: null
      };}
    },
    sortFunction: {
      type: Function,
      default: () => { return {}; }
    },
    selectable: {
      type: Boolean,
      default: false
    },
    allowDeletion: {
      type: Boolean,
      default: true
    },
    allowDuplication: {
      type: Boolean,
      default: false
    },
    allowModification: {
      type: Boolean,
      default: false
    },
    allSelected: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isColumnSelected: false,
      rowKey: 1
    };
  },

  computed: {
    ...mapState('datasets', [
      'currentUsergroupsPermissions'
    ])
  },

  watch: {
    currentUsergroupsPermissions: {
      deep: true,
      handler() {
        this.rowKey += 1;
      }
    }
  },


  methods: {
    selectColumn(value) {
      this.isColumnSelected = value;
      if (this.$refs.tableRowComponent) {
        for (const row of this.$refs.tableRowComponent) {
          row.selectRow(this.isColumnSelected);
        }
      }
    }
  }
};
</script>
