<template>
  <div class="search-bar">
    <input
      v-model="text"
      v-sanitize
      class="search-table"
      type="search"
      :placeholder="placeholder"
    >
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import _ from 'lodash';

export default {
  name: 'TableSearchbar',

  props: {
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    searchFunction: {
      type: Function,
      default: () => { return {}; }
    }
  },

  data() {
    return {
      text: null
    };
  },

  computed: {
    ...mapState('users', [
      'isUsersListSearched',
      'searchUsersFilter'
    ]),
    ...mapState('organisations', [
      'isOrganisationsListSearched',
      'searchOrganisationsFilter'
    ]),
    ...mapState('datasets', [
      'searchDatasetsFilter',
      'isDatasetsListSearched',
      'isPermissionsListSearched',
      'searchPermissionsFilter'
    ])
  },

  watch: {
    text: _.debounce(async function(newValue) {
      this.SET_TABLE_LOADER({
        name: this.name,
        loading: true
      });
      this.SET_CURRENT_PAGE({
        name: this.name,
        page: 1
      });
      try {
        const searched = await this.searchFunction({
          text: newValue,
          types: []
        });
        if (searched) {
          this.SET_TABLE_LOADER({
            name: this.name,
            loading: false
          });
        }
      } catch (err) {
        if (err && err.code && err.code !== 'ERR_CANCELED') {
          console.error(err);
          this.SET_TABLE_LOADER({
            name: this.name,
            loading: false
          });
        }
      }
      this.$emit('search', newValue && newValue.length);
    }, 100)
  },

  created() {
    if (this.isUsersListSearched && this.$router.currentRoute.name === 'Users') {
      this.text = this.searchUsersFilter;
    }
    if (this.isOrganisationsListSearched && this.$router.currentRoute.name === 'Organisations') {
      this.text = this.searchOrganisationsFilter;
    }
    if (this.isPermissionsListSearched && this.$router.currentRoute.name === 'DatasetPermissions') {
      this.text = this.searchPermissionsFilter;
    }
    if (this.isDatasetsListSearched && this.$router.currentRoute.name === 'Datasets') {
      this.text = this.searchDatasetsFilter;
    }
  },

  methods: {
    ...mapMutations('table', [
      'SET_TABLE_LOADER',
      'SET_CURRENT_PAGE'
    ])
  }
};
</script>
