import { render, staticRenderFns } from "./Head.vue?vue&type=template&id=53a9b5e4"
import script from "./Head.vue?vue&type=script&lang=js"
export * from "./Head.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports