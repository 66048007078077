var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',{attrs:{"role":"rowgroup"}},[_c('tr',[(_vm.selectable)?_c('th',{staticClass:"check-cell",staticStyle:{"width":"3%"},attrs:{"scope":"col"}},[_c('div',{staticClass:"form-check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.columnSelected),expression:"columnSelected"}],staticClass:"form-check-input-table",staticStyle:{"margin-left":"-1.35em"},attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.columnSelected)?_vm._i(_vm.columnSelected,null)>-1:(_vm.columnSelected)},on:{"click":function($event){$event.stopPropagation();return _vm.selectAllRows.apply(null, arguments)},"change":function($event){var $$a=_vm.columnSelected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.columnSelected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.columnSelected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.columnSelected=$$c}}}})])]):_vm._e(),_vm._l((_vm.fields),function(field){return _c('th',{key:field.key,style:({ width: field.width }),attrs:{"role":"columnheader","scope":"col"}},[_c('div',{staticClass:"cell-header",style:({
          'justify-content': field.centered ? 'center' : field.right ? 'flex-end' : '',
          'text-align': field.centered ? 'center' : '',
          'pointer-events': field.sortable ? '' : 'none'
        }),on:{"click":function($event){return _vm.sortField(field.apiName)}}},[(field.key === 'link')?_c('i',{staticClass:"bi bi-link",style:({ 'font-size': '1.2rem' })}):(field.key === 'Icon')?_c('span'):_c('span',[_vm._v(" "+_vm._s(field.key)+" ")]),(field.sortable)?_c('div',{staticClass:"sort-field"},[_c('b-icon-caret-up-fill',{class:{
              active: _vm.sortedUp && _vm.sortedField === field.apiName,
              disabled: _vm.sortedDown && _vm.sortedField === field.apiName
            }}),_c('b-icon-caret-down-fill',{class:{
              active: _vm.sortedDown && _vm.sortedField === field.apiName,
              disabled: _vm.sortedUp && _vm.sortedField === field.apiName
            }})],1):_vm._e()])])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }