<template>
  <tr
    role="row"
    @click="goTo(row)"
  >
    <td
      v-if="selectable"
      class="check-cell"
    >
      <div class="form-check">
        <input
          v-model="rowCheckbox"
          class="form-check-input-table"
          style="margin-left: -1.35em; margin-top: 0.2em;"
          type="checkbox"
          @click.stop="rowCheckbox = !rowCheckbox"
        >
      </div>
    </td>
    <td
      v-for="[key, value] in Object.entries(row).slice(1)"
      :key="key"
      role="cell"
      :style="{
        'text-align':
          key === $t('organisations.table.fields.usersCount') ||
          key ===$t('users.table.fields.registrationDate') ||
          key === $t('users.table.fields.loginDate') ||
          key === $t('organisations.table.fields.creationDate') ||
          key === $t('users.table.fields.status') ||
          key === 'Admin' ||
          key === $t('organisations.table.fields.signedConvention') ||
          key === $t('organisations.table.fields.convention') ||
          key === 'Nombre d\'organisations' ||
          key === 'Date de dernière révision' ||
          key === $t('datasets.table.fields.reviewDate') ||
          key === $t('datasets.table.fields.annexCount') ||
          key === $t('datasets.table.fields.publish') ||
          key === $t('datasets.table.fields.access') ||
          key === 'Niveau d\'accès'
            ? 'center' : '',
      }"
    >
      <div
        v-if="key === $t('users.table.fields.status')"
      >
        <b-icon
          v-if="
            $route.name === 'Users' ||
              $route.name === 'OrganisationDetail' ||
              $route.name === 'GroupDetail' ||
              $route.name === 'SphereDetail'"
          icon="person-fill"
          :variant="value === 2 ? 'success' : value === 1 ? 'warning' : value === 0 ? 'secondary' : 'dark'"
          font-scale="1.5"
        />
        <b-icon
          v-if="$route.name === 'Organisations'"
          icon="house-door-fill"
          :variant="value === 2 ? 'success' : value === 1 ? 'warning' : value === 0 ? 'secondary' : 'dark'"
          font-scale="1.5"
        />
      </div>
      <div
        v-else-if="key === 'Admin' || key === $t('organisations.table.fields.signedConvention')"
      >
        <div v-if="value">
          <b-icon
            icon="check"
            variant="success"
            font-scale="1.5"
          />
        </div>
      </div>
      <div
        v-else-if="key === $t('organisations.table.fields.convention')"
      >
        <div v-if="value">
          <b-icon
            class="convention-icon"
            style="cursor: pointer;"
            icon="file-earmark-text"
            variant="primary"
            font-scale="1.5"
            @click.stop="openConvention"
          />
        </div>
      </div>
      <div
        v-else-if="key === $t('tasks.status')"
        style="display: flex; align-items: center; justify-content: flex-end;"
      >
        <span>{{ value }}</span>
        <!-- <b-icon-check v-if="value === 'Succès'" variant="success" font-scale="2" />
        <b-icon-x v-else-if="value === 'Échec'" variant="danger" font-scale="2" />
        <b-icon-arrow-repeat
          v-else-if="value === 'PENDING'"
          variant="primary"
          font-scale="1.7"
          style="margin-left: 3px; margin-right: 1px;"
        />
        <b-icon-dash v-else variant="secondary" font-scale="2" /> -->
      </div>
      <div
        v-else-if="key === 'Icon'"
        style="display: flex; align-items: center;"
      >
        <b-icon-check
          v-if="value === 'SUCCESS'"
          variant="success"
          font-scale="2"
        />
        <b-icon-x
          v-else-if="value === 'FAILURE'"
          variant="danger"
          font-scale="2"
        />
        <b-icon-arrow-repeat
          v-else-if="value === 'PENDING'"
          variant="primary"
          font-scale="1.7"
          style="margin-left: 3px; margin-right: 1px;"
        />
        <b-icon-dash
          v-else
          variant="secondary"
          font-scale="2"
        />
      </div>
      <div v-else-if="key === $t('datasets.table.fields.publish')">
        <b-icon
          v-if="row[$t('datasets.table.fields.publish')]"
          icon="check"
          variant="success"
          font-scale="1.5"
        />
      </div>
      <div v-else-if="key === $t('datasets.table.fields.access')">
        <b-icon
          v-if="row[$t('datasets.table.fields.access')] === 0"
          icon="lock-fill"
          alt="Icon access unlock open"
          :color="'#0088ff'"
          font-scale="1"
          @click.stop="$emit('access', row.id)"
        />
        <div
          v-else
          style="width: fit-content; margin: auto;"
          @click.stop="$emit('access', row.id)"
        >
          <b-icon
            style="margin-right: 0.15em;"
            icon="search"
            :color="row[$t('datasets.table.fields.access')] > 0 ? '#0088ff' : '#ccc'"
            font-scale="1"
          />
          <b-icon
            style="margin-right: 0.15em;"
            icon="eye-fill"
            :color="row[$t('datasets.table.fields.access')] > 1 ? '#0088ff' : '#ccc'"
            font-scale="1"
          />
          <b-icon
            icon="cloud-arrow-down-fill"
            :color="row[$t('datasets.table.fields.access')] > 2 ? '#0088ff' : '#ccc'"
            font-scale="1"
          />
        </div>
      </div>
      <div
        v-else-if="key === 'Niveau d\'accès'"
      >
        <div
          :id="`popover-id-${row.id}`"
          style="width: fit-content; margin: auto;"
        >
          <b-icon
            style="margin-right: 0.15em;"
            icon="search"
            :color="row['Niveau d\'accès'] > 0 ? '#0088ff' : '#ccc'"
            font-scale="1.5"
          />
          <b-icon
            style="margin-right: 0.15em;"
            icon="eye-fill"
            :color="row['Niveau d\'accès'] > 1 ? '#0088ff' : '#ccc'"
            font-scale="1.5"
          />
          <b-icon
            icon="cloud-arrow-down-fill"
            :color="row['Niveau d\'accès'] > 2 ? '#0088ff' : '#ccc'"
            font-scale="1.5"
          />
        </div>
      </div>
      <div
        v-else-if="
          $route.name === 'Datasets' &&
            [
              $t('datasets.table.fields.name'),
              $t('datasets.table.fields.organisation'),
              $t('datasets.table.fields.mainResource')
            ].some(el => el === key)
        "
        :id="`${row.id}-${key}-${value}`"
      >
        {{ value }}
      </div>
      <div v-else-if="key === 'link'">
        <a
          :href="goToPortal(value)"
          target="_blank"
        >
          {{ value }}
        </a>
      </div>
      <div
        v-else
        :id="`${row.id}-${key}-${value}`"
      >
        {{ value }}
      </div>
      <b-popover
        v-if="
          $route.name === 'Datasets' &&
            [
              $t('datasets.table.fields.name'),
              $t('datasets.table.fields.organisation'),
              $t('datasets.table.fields.mainResource')
            ].some(el => el === key)
        "
        :target="`${row.id}-${key}-${value}`"
        placement="top"
        triggers="hover"
        :content="value"
      />
      <b-popover
        v-if="
          $route.name === 'Organisations' &&
            $t('organisations.table.fields.organisation') === key
        "
        :target="`${row.id}-${key}-${value}`"
        placement="top"
        triggers="hover"
        :content="value"
      />
    </td>
    <td
      v-if="allowModification"
      style="text-align: center; vertical-align: middle;"
    >
      <b-icon
        icon="file-earmark-diff"
        color="#0088ff"
        font-scale="1.25"
        @click.stop="modify(row)"
      />
    </td>
    <td
      v-if="allowDuplication"
      style="text-align: center; vertical-align: middle;"
    >
      <b-icon
        icon="files"
        color="#0088ff"
        font-scale="1.25"
        @click.stop="duplicateDataset(row)"
      />
    </td>
    <td
      v-if="allowDeletion"
      style="text-align: center; vertical-align: middle;"
    >
      <img
        v-if="
          !(
            usersList.find(el => el.id === row.id) &&
            usersList.find(el => el.id === row.id).is_superuser === true &&
            routeTo === 'UserDetail'
          )
        "
        src="@/assets/icons/trash_delete.svg"
        alt="Icon trash delete"
        @click.stop="$emit('delete', row)"
      >
      <img
        v-else
        class="disabled"
        src="@/assets/icons/trash_delete.svg"
        alt="Icon trash delete"
      >
    </td>
  </tr>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';

export default {
  name: 'TableRow',

  props: {
    routeTo: {
      type: String,
      default: null
    },
    row: {
      type: Object,
      default: () => { return {}; }
    },
    selectable: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    allowDeletion: {
      type: Boolean,
      default: true
    },
    allowDuplication: {
      type: Boolean,
      default: false
    },
    allowModification: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      rowCheckbox: false
    };
  },

  computed: {
    ...mapState('users', [
      'usersList'
    ]),
    ...mapState('datasets', [
      'selectedPermissions',
      'currentUsergroupsPermissions'
    ])
  },

  watch: {
    rowCheckbox(newValue) {
      this.$emit('select', {
        item: this.row,
        selected: newValue
      });
    },
    selectedPermissions: {
      deep: true,
      handler(newValue) {
        if (newValue.find(el => el.id === this.row.id)) {
          this.rowCheckbox = true;
        } else {
          this.rowCheckbox = false;
        }
      }
    },
    currentUsergroupsPermissions: {
      deep: true,
      handler() {
        if (
          this.selectedPermissions &&
          this.selectedPermissions.length &&
          this.selectedPermissions.find(el => el.id === this.row.id)
        ) {
          this.rowCheckbox = true;
        } else {
          this.rowCheckbox = false;
        }
      }
    }
  },

  created() {
    if (
      this.selectedPermissions &&
      this.selectedPermissions.length &&
      this.selectedPermissions.find(el => el.id === this.row.id)
    ) {
      this.rowCheckbox = true;
    } else {
      this.rowCheckbox = false;
    }
  },


  methods: {
    goTo(e) {
      if (this.routeTo) {
        this.$router.push({
          name: this.routeTo,
          params: { id: e.id }
        });
      }
    },

    selectRow(value) {
      this.rowCheckbox = value;
    },

    goToPortal(e) {
      return `${process.env.VUE_APP_PORTAIL_ROUTE}jeux-de-donnees/${e}`;
    },

    modify(e) {
      this.$emit('modify', e);
    },

    openConvention() {
      const url = new URL(this.row[this.$t('organisations.table.fields.convention')], process.env.VUE_APP_DOMAIN);
      axios.get(url, { responseType: 'arraybuffer' })
        .then((resp) => {
          if (!resp.data) {
            return;
          }

          const buffer = Buffer.from(resp.data, 'binary');
          const mimeType = resp.headers['content-type'];
          const contentDisposition = resp.headers['content-disposition'];
          let fileName;
          if (contentDisposition) {
            let matched;
            const regex = /^(attachment|inline);\sfilename="(?<fileName>[0-9a-zA-Z-_]+\.[a-zA-Z0-9]{3})"$/gm;
            while ((matched = regex.exec(contentDisposition)) !== null) {
              if (matched.index === regex.lastIndex) {
                regex.lastIndex++;
              }
              fileName = matched.groups.fileName;
            }
          } else {
            const fragment = url.href.split('/');
            fileName = fragment[fragment.length-1];
          }


          // https://developer.mozilla.org/en-US/docs/Web/API/Blob
          const newBlob = new Blob([buffer], { type: mimeType });

          // IE doesn't allow using a blob object directly as link href
          // instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
          }

          // On crée l'objet URL à partir du Blob
          const URL = window.URL || window.webkitURL;
          const downloadUrl = URL.createObjectURL(newBlob);
          const link = document.createElement('a');
          link.download = fileName;

          // On déroule
          link.href = downloadUrl;
          document.body.append(link);
          link.click();

          // Puis on révoque
          setTimeout(() => {
            // Délai nécessaire pour révoquer l'objet
            document.body.removeChild(link);
            URL.revokeObjectURL(downloadUrl);
          }, 100);
        });
    },

    duplicateDataset(e) {
      this.$emit('duplicate', e);
    }
  }
};
</script>

<style scoped>
.convention-icon:hover {
  transform: scale(1.04);
}
</style>
